<template>
  <b-modal
    id="payout-modal"
    ref="payout-setting-modal"
    hide-footer
    size="md"
    title="ตั้งค่าอัตราจ่าย"
    @show="resetModal"
    @hidden="resetModal"
  >
    <form
      ref="form"
      @submit.stop.prevent="handleSubmit"
    >

      <b-form-group
        label="ตั้งค่าให้"
        label-for="input-market-title"
      >
        <b-form-input
          id="input-market-title"
          type="text"
          placeholder="ชื่อใช้งาน"
          disabled
          v-model="accountUsername"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        label="หวย"
        label-for="input-market-title"
      >
        <b-form-input
          id="input-market-title"
          type="text"
          placeholder="หวย"
          disabled
          v-model="groupTitle"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        label="อัตราจ่าย"
        label-for="input-market-title"
      >
        <b-form-input
          id="input-market-title"
          type="text"
          placeholder="อัตราจ่าย"
          disabled
          v-model="rateTitle"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        label="อัตราจ่าย"
        label-class="text-primary"
        v-slot="{ ariaDescribedby }"
      >
        <table class="table-payrate table table-sm">
          <thead>
            <tr>
              <th width="40%">เลข</th>
              <th width="30%">จ่าย</th>
              <th width="30%">คอมมิชชั่น(%)</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="bet in marketOpenBets" :key="bet.code">
              <td>
                {{ bet.text }}
              </td>
              <td>
                <b-input-group size="sm" :append="rates[bet.code].pay.toString()">
                  <b-form-input
                    size="sm"
                    v-model="input.rateData[bet.code].pay"
                    :disabled="!input.rateData[bet.code].isAvailable"
                    :state="input.rateData[bet.code].pay <= rates[bet.code].pay"
                    :number="true"
                    required>
                  </b-form-input>
                </b-input-group>
              </td>
              <td>
                <b-input-group size="sm" :append="rates[bet.code].discount.toString()">
                  <b-form-input
                    size="sm"
                    v-model="input.rateData[bet.code].discount"
                    :disabled="!input.rateData[bet.code].isAvailable"
                    :state="input.rateData[bet.code].discount <= rates[bet.code].discount"
                    :number="true"
                    required>
                  </b-form-input>
                </b-input-group>
              </td>
            </tr>
          </tbody>
        </table>
      </b-form-group>

      <div class="d-flex justify-content-end">
        <b-button variant="link" size="sm" class="text-secondary mr-3" @click="hideModal">ยกเลิก</b-button>
        <b-button type="submit" variant="primary" size="sm" :disabled="isProcess">{{isProcess ? 'กำลังบันทึก...' : 'บันทึก'}}</b-button>
      </div>
    </form>
  </b-modal>
</template>
<script>
import {marketOpenBets} from '@/configs/market.config'
import AccountSettingService from "@/services/AccountSettingService"
import Swal from 'sweetalert2'
import cAlert from '@/helpers/alert'

const defaultInput = {}

export default {
  name: 'EditPayrateModal',
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    data: {
      type: [Object, String],
      default: null
    },
    refData: {
      type: Object
    }
  },
  computed: {
    marketOpenBets() {
      return marketOpenBets
    },
    rates() {
      return this.refData?.rates
    }
  },
  data() {
    return {
      needReload: false,
      isProcess: false,
      input: defaultInput,
      account: null,
      accountUsername: '',
      rateTitle: null,
      groupTitle: null
    }
  },
  watch: {
    isShow() {
      if(this.isShow){
        this.showModal()
      }else{
        this.hideModal()
      }
    },
    data() {
      if(this.data) {
        const data = JSON.parse(JSON.stringify(this.data))
        this.account = data.account
        this.accountUsername = data.account.user.username
        this.input = data.payRate

        const rate = this.$store.state.marketPayrates.find((r)=>{
          return r._id === data.payRate.rateId
        })
        this.rateTitle = rate?.rateTitle

        const group = this.$store.state.marketGroups.find((g)=>{
          return g._id === data.payRate.groupId
        })
        this.groupTitle = group?.groupTitle
      }
    }
  },
  methods: {
    resetModal() {
      this.needReload = false
      this.input = defaultInput
    },
    showModal() {
      this.$refs['payout-setting-modal'].show()
    },
    hideModal() {
      this.$refs['payout-setting-modal'].hide()
    },
    handleSubmit() {
      AccountSettingService.updateAccountSettingPayout(this.input)
      .then((response)=>{
        if(response.success) {
          this.needReload = true
          this.hideModal()
          this.$notify({
            type: 'success',
            title: 'สำเร็จ!',
            text: 'บันทึกเสร็จเรียบร้อย',
          })
        }else{
          throw new Error(response.data)
        }
      })
      .catch((e)=>{
        cAlert({
          ...e,
          title: 'ไม่สำเร็จ!',
          text: e?.message || 'แก้ไขข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
    }
  },
  mounted() {
    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      this.$emit('close', this.needReload)
    })
  }
}
</script>
<style lang="scss" scoped>
.table-payrate {
  td {
    vertical-align: middle;
  }
}
</style>
